<template>
  <div class="view pa24">
    <div class="d-flex mb20">
      <div class="bgf7fa pt20 pr20 pl20 pb20 d-flex flex-column align-items-center mr20">
          <div class="d-flex align-items-center">
              <p class="fwbold fs10">收入合计</p>
              <el-tooltip
                class="item ml10"
                effect="dark"
                content='累计商品收入-累计分销佣金支出'
                placement="top"
              >
                <i style="font-size: 16px" class="el-icon-question" />
              </el-tooltip>
            </div>
          <p class="fwbold fs10">￥{{totalSells}}</p>
      </div>
      <div class="bgf7fa pt20 pr20 pl20 d-flex flex-column align-items-center mr20">
          <p class="fwbold fs10">累计商品收入</p>
          <p class="fwbold fs10">￥{{totalPrice}}</p>
      </div>
      <div class="bgf7fa pt20 pr20 pl20 d-flex flex-column align-items-center">
          <p class="fwbold fs10">累计分销佣金支出</p>
          <p class="fwbold fs10">￥{{totalCommission}}</p>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <el-date-picker
        v-model="pickerValue"
        class="mr10 "
        align="right"
        value-format="yyyy-MM-dd"
        type="daterange"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      />
      <el-input class="mr20 w200"  v-model="keyword" placeholder="企业名称/管理员电话"></el-input>
      <el-button type="primary" @click="queryVisitorsData">查询</el-button>
      <!-- <el-button type="primary" @click="queryVisitorsData">导出</el-button> -->
    </div>
    
    <commonTable
      :tableData="tableData"
      :loading="loading"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column
            prop="companyId"
            align="center"
            label="编号"
            show-overflow-tooltip
          />
        <el-table-column prop="companyName" align="center" label="企业名称" />
        <el-table-column prop="nickName" align="center" label="管理员" />
        <el-table-column prop="phone" align="center" label="管理员电话" />
        <el-table-column prop="totalPay" align="center" label="商品收入" />
        <el-table-column prop="commission" align="center" label="分销佣金支出" />
        <el-table-column prop="balance" align="center" label="剩余可提现佣金" />
        <el-table-column prop="visitTime" align="center" label="操作">
          <template slot-scope="scope">
            <p class="cblue hover_pointer" @click="toDetailPage(scope.row.companyId)">详情</p>
          </template>
        </el-table-column>
        
      </template>
    </commonTable>

  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { getDataTimeSec } from "@/utils";
import {  
  bossSubordinationBalance
 } from "@/api/financing";
export default {
  name: "visitorData",
  components: {
    commonTable,
  },
  data() {
    return {
      pickerValue: "", //选择时间
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      staffs: [],
      userId: "",
      sourcesId: "",
      authsId: "",
      phone: "",
      startTime: this.startTime,
      endTime: this.endTime,
      totalSells:0, //收入合计
      totalPrice:0, //累计商品收入
      totalCommission:0 ,//累计佣金
      keyword:''
    };
  },
  filters: {
    getDataTimeSec(val) {
      return getDataTimeSec(val);
    },
  },
  created() {
    this.queryVisitorsData();
  },
  methods: {
    async queryVisitorsData() {
      var patrn = /^(-)?\d+(\.\d+)?$/;
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        phone: '',
        companyName:'',
        startTime: this.pickerValue && `${this.pickerValue[0]} 00:00:00`,
        endTime: this.pickerValue && `${this.pickerValue[1]} 23:59:59`,
      };
      
      if(this.keyword){
        if(patrn.exec(this.keyword)){
          data.phone = this.keyword
        }else{
          data.companyName = this.keyword
        }
      }
      try {
        this.loading = true;
        const result = await bossSubordinationBalance(data);
        this.loading = false;
        const { total, list } = result?.data?.pageInfo;
        this.tableData = list;
        this.tableData.forEach(item=>{
          item.balance = (item.balance/100).toFixed(2)
          item.commission = (item.commission/100).toFixed(2)
          item.totalPay = (item.totalPay/100).toFixed(2)
        })
        this.totalSells = (result.data.totalSells/100).toFixed(2)
        this.totalPrice = (result.data.totalPrice/100).toFixed(2)
        this.totalCommission = (result.data.totalCommission/100).toFixed(2)
        this.total = total;
      } catch (error) {
        this.tableData = []
        this.loading = false;
        console.log(error);
      }
    },
    formatSeconds(value) {
      var theTime = parseInt(value);// 秒
      var theTime1 = 0;// 分
      var theTime2 = 0;// 小时
      if(theTime > 60) {
        theTime1 = parseInt(theTime/60);
        theTime = parseInt(theTime%60);
        if(theTime1 > 60) {
          theTime2 = parseInt(theTime1/60);
          theTime1 = parseInt(theTime1%60);
        }
      }
      var result = ""+parseInt(theTime)+"秒";
      if(theTime1 > 0) {
        result = ""+parseInt(theTime1)+"分"+result;
      }
      if(theTime2 > 0) {
        result = ""+parseInt(theTime2)+"小时"+result;
      }
      return result;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryVisitorsData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryVisitorsData();
    },
    toDetailPage(id){
      this.$router.push({ path:`/moneyForDetails?id=${id}` });
    }
  },
};
</script>

<style lang="scss" scoped>
</style>